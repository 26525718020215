.logo_button_primary {
    border: none ;
    cursor: pointer;
    background-color: transparent;
}

.log_button_img_primary {
    display: block;
    border: none;
    background: transparent;
    width: 125px;
    height: 125px;
}