.course_display_grid_background_primary {
    background-color: #fefaeb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    position: relative;
    height: 35vw;
}

.course_display_grid_background_primary .col {
    display: flex;
    justify-content: center;
    align-items: center;       /* 垂直居中 */
    margin: 0.5rem;
}

.course_display_grid_background_primary .row {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
