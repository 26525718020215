.loading_ring_container { 
    display:flex;
    justify-content: center;
    align-items:center ;
    height:100%;   
}

.loading_ring {
    width: 250px;
    height:250px;
    border-radius: 50%;
    border:0px solid black;
    position:absolute;
} 

.loading_ring:nth-child(1){
     border-bottom-width: 8px;
     border-color: rgb(7, 48, 253);
     animation:animate 2s linear infinite;
}
@keyframes animate{
       from{
            transform:rotateX(30deg) rotateY(-45deg) rotateZ(0deg);
           }
       to{
          transform:rotateX(30deg ) rotateY(-45deg) rotateZ(360deg); 
         }    
          }
 
.loading_ring:nth-child(2){
     
        border-right: 8px solid rgba(9, 209, 244, 0.979);
        animation:animes 2s linear infinite;
   }
   @keyframes animes {
       from{transform:rotateX(50deg) rotateY(10deg) rotateZ(0deg);
           }
       to{
           transform:rotateX(50deg ) rotateY(10deg) rotateZ(360deg); 
       }    
       }

       .loading_ring:nth-child(3){
     
        border-left: 8px solid rgba(242, 127, 101, 0.979);
        animation:animess 2s linear infinite;
   }
   @keyframes animess {
       from{transform:rotateX(35deg) rotateY(55deg) rotate(0);
           }
       to{
           transform:rotateX(35deg ) rotateY(55deg) rotatez(360deg); 
       }    
       }
       h1{
        color: rgba(15, 243, 117, 0.998);
       }