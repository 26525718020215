/* css/ClockContainer.css */
.clock-container {
    position: relative;
    width: 30vw;
    height: 30vw;
    margin: 40px;
    /* overflow: hidden; */
}

/* css/ClockContainer.css */

.clock-drop-area {
    position: absolute;
    width: 10.4%;
    height: 7.1%;
    top: 44%;
    left: 44%;
    transform-origin: center;  /* 確保旋轉的中心是元素的中心 */
    transition: background-color 0.3s;
}

.clock-content, .empty-cell {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    cursor: pointer;
}

.clock-content.is-over, .empty-cell.is-over {
    background-color: #e0e0e0;
}

.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    line-height: 1;
    color: red;
}
