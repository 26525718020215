.main_page_container_flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transform-origin: left top;
    transform: scale(1);
    background-image: url('../../../public/homePagebg.JPG');
    background-size: 100vw auto;
    background-repeat: no-repeat; 
}

.main_page_logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-left: 10vw !important; */
}

.main_page_footer {
    width: 100%;
}

.main_page_content {
    flex: 1;
}

