.circle_button_small_primary, .circle_button_small_selected_primary {
    font-weight: bold;
    /* font-size: 20px;
    width: 40px;
    height: 40px; */
    font-size: 1.5vw;
    width: 3vw;
    height: 3vw;
    color: #ffffff;
    background-color: #4db6f7;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    border: 3px solid white;
    padding: 5px 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .circle_button_small_primary {
    background-color: #4db6f7;
} */

.circle_button_small_primary:hover {
    background-color: #00ccfc;
}

.circle_button_small_selected_primary {
    color: #ffff41;
}
