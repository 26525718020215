.course_display_image_button {
    display: flex;             /* 將此設置為flex container */
    flex-direction: column;   /* 元素堆疊為列 */
    align-items: center;      /* 水平居中 */
    justify-content: center;  /* 垂直居中 */
    width: 100%;              /* 按鈕填滿其容器 */
    border: none;             /* 去除預設的按鈕邊框 */
    padding: 0;               /* 去除按鈕的padding */
    background: transparent;  /* 使其透明 */
}

.course_display_image {
    max-width: 85%;          /* 圖片不會超出其容器 */
    height: auto;             /* 維持圖片的長寬比 */
    display: block;           /* 防止圖片下方有小間隙 */
}

.course_display_title {
    text-align: center;       /* 文本居中對齊 */
    margin-top: 0.5rem;       /* 在圖片和文本之間加一點間隔 */
}
