.sensory_sub_button_primary, .sensory_sub_button_selected_primary {
    font-weight: 900;
    font-size: min(calc(1vw), 10px);
    width: calc(5vw);
    height: calc(5vw);
    max-width: 80px;
    max-height: 80px;
    color: #ffffff;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    border: 3px solid white;

    display: flex;           /* 將按鈕設置為 flex 容器 */
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
}

.sensory_sub_button_primary {
    background-color: #0b96db;
}

.sensory_sub_button_primary:hover {
    background-color: #1ec9c9;
}

.sensory_sub_button_selected_primary {
    background-color: #fec104;
}

@media (max-width: 768px) {
    .sensory_sub_button_primary, .sensory_sub_button_selected_primary {
        width: 18vw;  /* Adjust this as per your requirements */
        height: 6vw;  /* Adjust this as per your requirements */
        /* ... other styles */
    }
}
