.academic_theory_box_img {
    border-radius: 50%;
    object-fit: cover;
    height: 6vw;
    width: 6vw;
    cursor: pointer;
    transition: transform 0.8s ease, border-radius 0.8s ease;
    max-width: 100%;
    max-height: 100%;
}

.academic_theory_box_img:hover {
    transform: scale(1.6);
    border-radius: 0;
}