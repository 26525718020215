.language_switch_btn_primary {
    display: inline-flex;
}

.language_switch_btn_left {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    background-color: #fda800;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 2px 15px;
    border-right: 1px solid white;
    border-left: 3px solid white;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
}

.language_switch_btn_left:hover {
    color: #feea02;
}

.language_switch_btn_right {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    background-color: #fda800;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 2px 15px;
    border-left: 1px solid white;
    border-right: 3px solid white;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
}

.language_switch_btn_right:hover {
    color: #feea02;
}