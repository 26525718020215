.single_video_page_container_flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transform-origin: left top;
    transform: scale(1);
}

.single_video_page_auto_grow {
    flex-grow: 1;
}

.single_video_page_logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10vw !important;
}

.single_video_page_button_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 50px;
    padding-left: 10vw;
}

.single_video_page_button_left > * {
    margin-bottom: 5vh;  /* 使用固定的px单位 */
}

.single_video_page_button_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120%;
    padding-top: 50px;
    position: relative;  /* 使用絕對定位 */
    padding-right: 10vw;
}

.single_video_page_button_right > * {
    margin-bottom: 4vh;
}

.single_video_page_button_right > *:nth-last-child(2) {
    margin-bottom: 8vh; /* 或您希望的其他值 */
}

.single_video_page_course_button {
    position: absolute;  /* 使用絕對定位 */
    bottom: 0;       /* 距離底邊的位置，您可以根據需要調整 */
    left: 50%;          /* 使元素水平居中 */
    transform: translateX(-50%);
}

.single_video_page_circle_small_button {
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.single_video_page_player_container {
    display: flex;
    align-items: center;     /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-grow: 1;            /* 允許flex容器放大 */
    overflow: hidden;        /* 確保影片不會超出容器 */
    max-width: 80%;
    margin: 0 auto;          /* 容器自身在其父元件中居中 */
}

.single_video_page_footer { /* 請使用您Footer組件的實際類名 */
    /* position: absolute; */
    /* bottom: 0; */
    width: 100%;
}

.single_video_page_content {
    flex: 1;
}

.single_video_page_loading_ring{
    padding-top: 10vw;
}

@media (max-width: 768px) {
    .buttonCol {
        width: 100%;
    }

    .single_video_page_button_left, .single_video_page_button_right, .single_video_page_circle_small_button {
        flex-direction: row;   /* Make the buttons horizontal */
        justify-content: space-between;   /* Distribute buttons evenly */
        padding: 1rem 0;  /* Add some padding on top and bottom */
    }

    /* If you have specific styles for CourseButton at larger screens, reset them here */
    .single_video_page_button_left > *, .single_video_page_button_right > * {
        margin: 0;   /* Remove any margins */
    }

    .course_test_button_container {
        position: static;  /* 為了在小螢幕上正常顯示，移除絕對定位 */
        transform: none;   /* 移除 transform */
        margin-top: 1rem;  /* 設定頂部的間隙 */
    }

    .single_video_page_button_right > *:nth-last-child(2) {
        margin-bottom: 0;
    }
}