.multiple_grid_container_background_primary {
    background-color: #fefaeb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    position: relative;
    height: 35vw;
    padding: 0;
}

.multiple_grid_container_background_primary .col {
    display: flex;
    justify-content: center;   /* 水平居中 */
    align-items: center;       /* 垂直居中 */
    padding: 0;
}

.multiple_grid_container_background_primary .row {
    flex-grow: 1;
    display: flex;
}

/* 增加一個具體的高度和寬度，這取決於您的具體需求 */
.grid-cell {
    height: 100%; 
    width: 100%;
}

.grid-drop-area {
    position: relative;
    height: 100%;
    width: 100%;
}

.grid-content {
    width: 100%;
    height: 100%;
    background: lightgray;
    position: relative;
    border: 1px solid black; /* default border */
    box-sizing: border-box;
}

.grid-content.is-over {
    border: 2px dashed gray;
}

.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: none;
    color: white;
    cursor: pointer;
}

.empty-cell {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-cell.is-over {
    border: 2px dashed gray;
}
