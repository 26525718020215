.Login_container_background_primary {
    background-color: #fefaeb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    position: relative;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.login_container_header_section {
    font-size: 32px;
    background-color: #fcb409;
    color: white;
    height: 33.33%; /* 1/3 的高度 */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* 上面兩行使內容置中 */
}

.login_container_content_section {
    background-color: white;
    color: #355bb6;
    height: 66.67%; /* 2/3 的高度 */
    width: 100%;
    padding: 20px; /* 些許的內間距，可以根據需要調整 */
    text-align: center; /* 這行使內容水平置中 */
}

.login_container_input_label {
    margin-top: 10px;
    margin-bottom: 5px;
}
