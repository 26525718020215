.multiple_grid_page_container_flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transform-origin: left top;
    transform: scale(1);
}

.multiple_grid_page_footer {
    /* position: absolute;
    bottom: 0; */
    width: 100%;
}

.multiple_grid_page_content {
    flex: 1;
}
