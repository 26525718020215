.sensory_integration_steps_container::-webkit-scrollbar {
    display: none;
}

.sensory_integration_steps_container {
    display: flex;
    overflow-x: hidden; /* 隐藏水平滚动条 */
    width: 100%; /* 容器的宽度 */
}

.sensory_integration_steps_item {
    width: 100%; /* 每个步骤的宽度 */
    flex: 0 0 100%; /* 防止项目伸缩，使它们保持相同的宽度 */
    transition: transform 0.3s ease-out; /* 为移动添加动画 */
}
