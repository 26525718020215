.multiple_video_player_background_primary {
    background-color: #fefaeb;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    position: relative;
}

.multiple_video_player_background_primary .row,
.multiple_video_player_background_primary .col {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.multiple_video_player_draggable_progress_bar {
    width: 15px;
    height: 15px;
    background-color: red;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.multiple_video_player_progress_bar {
    flex: 10;
    width: 100%;
    max-width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    position: relative;
    cursor: pointer;
    z-index: 1;
    margin-left: 26px;
}

.multiple_video_player_progress_bar_fill {
    height: 100%;
    background-color: #2196F3;
    position: relative;
}

.multiple_video_player_time {
    flex: 2;
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    color: black;
}

.multiple_video_player_progress_dot {
    position: absolute;
    width: 38px;
    height: 38px;
    left: 100%;
    top: 50%; /* 添加此行 */
    transform: translateX(-50%) translateY(-50%); /* 修改此行 */
    border-radius: 50%;
    box-shadow: 2px 3px 3px rgba(0%, 0%, 0%, 0.16);
    background-color: red;
}

.multiple_video_player_progress_dot:hover {
    transform: scale(1.1) translateX(-45%) translateY(-45%);
}


.multiple_video_player,
.multiple_video_player_progress_bar,
.multiple_video_player_time,
.multiple_video_player_full_screen_btn {
    display: inline-block;
    margin-right: 0;
}

.multiple_video_player {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;  /* 添加這行 */
}

.multiple_video_player_button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s;
    width: 60px;
    height: 60px;
    background-color: #98ddf1;
    border: 3px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.multiple_video_player_button img {
    width: 350%;
    height: auto;
}

.multiple_video_player_button.hidden,
.hidden {
    opacity: 0;
    pointer-events: none;
    display: none;
}

.hidden {
    transition: opacity 0.5s ease;
}

.multiple_video_player_progress_bar,
.multiple_video_player_time,
.multiple_video_player_full_screen_btn {
    transition: opacity 0.5s ease;
}
.multiple_video_player_full_screen_btn {
    flex: 1;
    background: transparent; /* 設置背景為透明 */
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; /* 移除內間距以適配圖片 */
    width: 38px; /* 設置按鈕寬度 */
    height: 38px; /* 設置按鈕高度 */
    margin-right: 9px;
}

.multiple_video_player_full_screen_btn img {
    width: 38px; /* 設置圖片寬度 */
    height: 38px; /* 設置圖片高度 */
    transition: transform 0.3s ease; /* 添加過渡效果 */
}

.multiple_video_player_full_screen_btn:hover img {
    transform: scale(1.1); /* 使圖片放大10% */
}

.controls {
    position: absolute;
    bottom: 15px;
    left: 17px;
    right: 17px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.0); /* 添加半透明黑色背景 */
    border-radius: 10px; /* 添加圓角 */
    margin-bottom: 9px;
}