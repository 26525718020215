.login_btn_small_primary {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff !important;
    background-color: #45b600;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: 3px solid white;
    padding: 5px 20px;
}

.login_btn_small_primary:hover {
    background-color: #30db1c;
}