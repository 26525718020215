.academic_theory_page_container_flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transform-origin: left top;
    transform: scale(1);
}

.academic_theory_page_footer {
    /* position: absolute;
    bottom: 0; */
    width: 100%;
}

.academic_theory_page_content {
    flex: 1;
}

.academic_theory_page_button_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 50px;
}

.academic_theory_page_button_left > * {
    margin-bottom: 5vh;  /* 使用固定的px单位 */
}

.academic_theory_page_button_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.academic_theory_page_button_right > * {
    margin-bottom: 5vh;  /* 使用固定的px单位 */
}

@media (max-width: 768px) {
    .buttonCol {
        width: 100%;
    }

    .academic_theory_page_button_left, .academic_theory_page_button_right {
        flex-direction: row;   /* Make the buttons horizontal */
        justify-content: space-between;   /* Distribute buttons evenly */
        padding: 1rem 0;  /* Add some padding on top and bottom */
    }

    /* If you have specific styles for CourseButton at larger screens, reset them here */
    .academic_theory_page_button_left > *, .academic_theory_page_button_right > * {
        margin: 0;   /* Remove any margins */
    }
}