.white_btn_primary {
    font-weight: bold;
    font-size: 16px;
    color: #fd7202 !important;
    background-color: #ffffff;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 5px 20px;
    border: none;
}

.white_btn_primary:hover {
    background-color: #ffff65;
}