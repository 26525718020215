.course_test_page_container_flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transform-origin: left top;
    transform: scale(1);
}

.course_test_page_logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10vw !important;
}

.course_test_page_loading_ring{
    padding-top: 10vw;
}

.course_test_page_footer {
    /* position: absolute;
    bottom: 0; */
    width: 100%;
}

.course_test_page_content {
    flex: 1;
}

.course_test_page_button_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 50px;
    padding-left: 10vw;
}

.course_test_page_button_left > * {
    margin-bottom: 5vh;  /* 使用固定的px单位 */
}

.course_test_page_player_container {
    display: flex;
    align-items: center;     /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-grow: 1;            /* 允許flex容器放大 */
    overflow: hidden;        /* 確保影片不會超出容器 */
    max-width: 80%;
    margin: 0 auto;          /* 容器自身在其父元件中居中 */
}

@media (max-width: 768px) {
    .buttonCol {
        width: 100%;
    }

    .course_test_page_button_left {
        flex-direction: row;   /* Make the buttons horizontal */
        justify-content: space-between;   /* Distribute buttons evenly */
        padding: 1rem 0;  /* Add some padding on top and bottom */
    }

    /* If you have specific styles for CourseButton at larger screens, reset them here */
    .course_test_page_button_left > * {
        margin: 0;   /* Remove any margins */
    }
}