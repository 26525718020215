body {
    background-color: #fffae1;
    background-image: url("../public/bgImg.png");
    background-repeat: repeat-x;
    background-size: 50px 50px;
    background-position-y: 35vw;
    position: relative;
    margin: 0;
    font-family: 'Microsoft YaHei', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}