.academic_theory_container_background_primary {
    background-color: #fefaeb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem;
    margin: 0rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    position: relative;
    height: 35vw;
}

.academic_theory_container_background_primary .col {
    display: flex;
    justify-content: center;   /* 水平居中 */
    align-items: center;       /* 垂直居中 */
}

.academic_theory_container_background_primary .row {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
