.clickable_grid_background {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
    grid-auto-rows: 2fr;
}

.clickable_grid_header,
.clickable_grid_side_label,
.clickable_grid_cell {
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: inline-block;
    width: 90%;
    height: 90%;
    text-align: center;
    padding: 2vw;
    border-radius: 5px;
    color: white;
}

.clickable_grid_cell{
    background-color: #B7DDF6;
    transition: transform 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.clickable_grid_cell:hover {
    transform: scale(1.1); /* Zoom in effect */
}

.clickable_grid_header,
.clickable_grid_side_label{
    background-color: #96C2F2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.clickable_grid_side_label {
    /* writing-mode: vertical-lr; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.clickable_return_button {
    position: absolute;
    right: 5rem;
    bottom: 0;
}

